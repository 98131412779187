@import url("https://use.typekit.net/nzg8ysh.css");
/* https://res.cloudinary.com/dgps1radv/image/upload/c_fill,w_200/mto5squ8abpmd02zymev */
$red: #fc5861;
$black: #222;
$borderWidth: 4px;
$paddingY: 0.35em;
$sigFont: gooddog-new, sans-serif;
// .site-content {
//   background: $black;
//   /* background: none; */
//   color: white;
// }
// .gourmet88 {
//   background: url(https://gourmet88.com//wp-content/uploads/2012/02/gourmet88-background-image-1.jpg) no-repeat;
//   background-size: contain;
// }
$starBG: white;
$star: #2356b7;
.donuthut {

  font-family: $sigFont;
  &.site-content {
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='%232356b7' fill-opacity='0.4'%3E%3Cpolygon fill-rule='evenodd' points='8 4 12 6 8 8 6 12 4 8 0 6 4 4 6 0 8 4'/%3E%3C/g%3E%3C/svg%3E");
  }
  .photos.grid {
    background: white;
    .menu-item {
    }
  }


  img.nav-bar-logo {
    display: block;
    // padding-left: 0.8em;
  }
  .cart.minimized {
    // background-color: #ffe414;
    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 100 100'%3E%3Cg stroke='%23f77777' stroke-width='0' %3E%3Crect fill='%23f57373' x='-60' y='-60' width='240' height='110'/%3E%3C/g%3E%3C/svg%3E");
    background: #ffffb3;
    // padding-bottom: 0.35em;
    border: none;
    /* background: black; */
    border-bottom: 2px solid $red;
    /* background: repeating-linear-gradient(
    90deg,
    #dd0016,
    #dd0016 40px,
    #eee 40px,
    #eee 80px
    ); */
  }

  .landing-page, .top-banner {
    font-family: $sigFont;
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 200px;
    /* max-height: 50vh; */
    // background: url(https://image.shutterstock.com/z/stock-photo-chinese-food-on-dark-wooden-table-noodles-fried-rice-dumplings-stir-fry-chicken-dim-sum-466836857.jpg);
    // background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/gseysmrjllcnuob9l2xs);
    background: $red;
    background-size: cover;
    background-position: center 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    .icon {
      width: 300px;
      animation-name: spin;
      animation-duration: 500ms;
    }
    @keyframes spin {
      from {
        transform:rotate(0deg) scale(0);
      }
      to {transform:rotate(360deg) scale(1);}
    }
  }
  .landing-page .text {
    color: white;
    // background: rgba(0,0,0,0.5);
    padding: 0.5em 0.8em;
  }

  a.checkout-button.button {
    background: $red;

    font-weight: 600;
    box-shadow: none;
    border: 2px solid #eee;

  }

  /* .category__header {
  font-family: cursive;

} */
.category-name .text {
  font-family: $sigFont;
  font-weight: 400;
  font-size: 1.8em;
  color: $red;
  background: none;

}
.category-name .text::before {
  content: "• "
}
.category-name .text::after {
  content: " •"
}
.top-banner {
  // height: 30vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  color: white;
  text-shadow: 1px 1px 0 $red;
}

.pages-nav {
  font-family: $sigFont;
  background: $red;
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 480px) {
    font-size: 4vw;
  }
}

a.page-link {
  text-decoration: none;
  color: white;
  display: block;
  padding: $paddingY 0.3em;
  padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
  // box-sizing: border-box;
  border-bottom: $borderWidth solid $red;
  &:hover, &.active {
    border-bottom: $borderWidth solid white;
  }
}

.full-menu-link-wrapper {
  padding: 1em 0;
  text-align: center;
  a.button {
    background: $red;
    color: white;
  }
}
}
